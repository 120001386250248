<template>
    <!-- <Mario /> -->
    <div class="hh">
        <Head />
    </div>
    <Servis />
    <Foot />
</template>

<script>
import Mario from '@/components/Mario.vue'
import Head from '@/components/Head.vue'
import Servis from '@/components/Servis.vue'
import Foot from '@/components/Foot.vue'

export default {
    name: 'ServisView',
    components: {
        Mario,
        Head,
        Servis,
        Foot,
    }
}
</script>

<style scoped>
.hh {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>