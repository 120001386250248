<template>
    <div class="hed">
        <nav>
            <div class="mr"></div>
            <router-link to="/">Главная</router-link>
            <router-link to="/about">Примеры работ</router-link>
            <div class="telega">
                <a href="https://t.me/DimaLap" target="_blank" rel="noopener"
                >
                    <img class='vk' alt="" src="../assets/telega.png"/>
                </a>
            </div>
            <a 
                class="mp"
                href="mailto:d.lap@mail.ru"
            >
                d.lap@mail.ru
            </a>
            <a href="tel:+79876727522"
            >
                <button>
                    +7-987-672-75-22
                </button>
            </a>
            <div class="mr"></div>
        </nav>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .hed {
        background: white;
    }
    nav {
        height: 12vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    nav a {
        font-weight: bold;
        font-size: 1.8vw;
        color: #2c3e50;
        text-decoration: none;
        transition: all 0.4s ease;
    }
    nav a:hover {
        color: #E36016;
        transition: all 0.4s ease;
    }
    nav a.router-link-exact-active {
        color: #E36016;
    }
    .telega {
        margin-top: 0.5vh;
    }
    .vk {
        width: 3vw;
        transition: all 0.4s ease;
    }
    .vk:hover {
        transform: scale(1.1);
        transition: all 0.4s ease;
    }
    .mp {
        font-size: 2vw;
    }
    button {
        background-color: #E36016;
        border: #E36016;
        color: white;
        width: 20vw;
        height: 7vh;
        font-size: 1.8vw;
        font-weight: bold;
        transition: all 0.4s ease;
        cursor: pointer;
    }
    button:hover {
        background-color: #333333;
        border: #333333;
        color: white;
        transition: all 0.4s ease;
    }    

    @media (max-width: 767px) {

        .mr {
            height: 2vh;
        }
        nav {
            height: 30vh;
            display: grid;
        }
        nav a {
            font-size: 20px;
        }
        .vk {
            width: 12vw;
        }
        .mp {
            font-size: 20px;
        }
        button {
            font-size: 23px;
            width: 80vw;
        }
    }

</style>