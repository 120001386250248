<template>
  <div class="stage">
    <div class="actor"></div>
    <div class="actor"></div>
    <div class="actor"></div>
  </div>
</template>

<script>
export default {
  name: 'Afi',
}
</script>

<style scoped>
.stage {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 50vw;
}
.actor {
  animation: kenburns 30s linear infinite;
  background-size: cover;
  background-position: 50%;
  height: 100vh;
  inset: 0;
  opacity: 0;
  position: absolute;
  scale: 1.2;
  width: 50vw;
}
.actor:nth-child(1) {
  background-image: url("https://i.playground.ru/e/c4LTpPLGNtpfk-XHIVT-iQ.jpeg");
}
.actor:nth-child(2) {
  animation-delay: 10s;
  background-image: url("https://img.championat.com/s/1350x900/news/big/x/g/multfilm-bratya-super-mario-v-kino-nachnut-pokazyvat-v-rossii-s-13-aprelya_16807885801549626518.jpg");
}
.actor:nth-child(3) {
  animation-delay: 20s;
  background-image: url("https://4kwallpapers.com/images/wallpapers/the-super-mario-2880x1800-9536.jpg");
}
@keyframes kenburns {
  25% {
    opacity: 1;
  }
  50% {
    opacity: .8;
    scale: 1;
  }
  60% {
    opacity: 0;
  }
}

@media (max-width: 767px) {

  .stage {
    width: 100vw;
  }
  .actor {
    background-position: 50%;
    width: 100vw;
  }

}
</style>
