<template>
  <section>
    <img src="https://a-static.besthdwallpaper.com/mario-with-princess-peach-toad-super-mario-bros-movie-wallpaper-2736x1824-109723_41.jpg" alt="">
    <p>
      JavaScript <br>
      Vue.js <br>
      Node.js <br>
      Express.js <br>
      Базы данных <br>
      Библиотеки <br>
      Сервисы
    </p>
  </section>
  <section>
    <img src="https://kakogo-chisla.ru/wp-content/uploads/2023/02/Pochemu-vazhen-Den-Mario.jpg" alt="">
    <div class="ttx">
      <p>
        Что нужно для сайта?
      </p>
      <p>
        Техническое задание <br>
        Изображения <br> 
        Видеоролики <br> 
        Тексты <br>
        Ссылки итд.
      </p>
    </div>
  </section>
  <div class="mr"></div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default {
  name: 'Opis',

  setup() {
    onMounted(() => {

      gsap.registerPlugin(ScrollTrigger);

      let sections = gsap.utils.toArray("section");

      sections.forEach((section) => {
        // scoped selector - select elements inside this section.
        let q = gsap.utils.selector(section)
        let tl = gsap.timeline({
          delay: 0.5,
          defaults: {
            duration: 1,
            ease: "sine.out"
          },
          scrollTrigger: {
            trigger: section,
            fastScrollEnd: true,
            // markers: true,
            start: "top bottom",
            end: "top 80%", // fastScrollEnd triggers as we leave the section so make sure you have an end position set low down enough to see the impact.
            toggleActions: "play none none reverse",
          }
        });
        tl.from(q('img'), {
          scale: 0.8,
          opacity: 0
        })
        .from(q('p'), {
          y: 50,
          opacity: 0
        },'<50%')
      });
    })
  }
}
</script>

<style scoped>
section {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 3vw;
  padding: 1vw;
}
section:nth-of-type(odd) {
  flex-direction: row-reverse;
}
section > * {
  flex: 1 1 30vw;
}
.ttx {
  display: block;
}
p {
  text-align: center;
  font-size: 2vw;
  color: #2c3e50;
}
img:not(.greensock-icon) {
  width: 30vw;
  height: 70vh;
  object-fit: cover;
}
.mr {
  height: 10vh;
}

@media screen and (max-width: 767px) {
  section {
    display: block;
    padding: 0;
  }
  img:not(.greensock-icon) {
    width: 100vw;
  }
  p {
    font-size: 25px;
  }
}

</style>