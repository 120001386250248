<template>
  <div class="grid">
    <div class="item">
      <button>
        <a href="https://xn--21-6kc4beenxfe0l.xn--p1ai/" target="_blank" rel="noopener">
          <p>Стоматология Стомалюкс</p>
        </a>
      </button>
      <div class="img-wrapper">
        <img src='../assets/im1.png' alt=''>
      </div>
    </div>
    <div class="item">
      <button>
        <router-link to="/servis">
          <p>Онлайн-сервис</p>
        </router-link>
      </button>
      <div class="img-wrapper">
        <img src='../assets/int3.jpg' alt=''>
      </div>
    </div>
    <div class="item">
      <button>
        <a href="https://xn--80aca1ahkk.xn--p1ai/" target="_blank" rel="noopener">
          <p>Библиотека г.Канаш</p>
        </a>
      </button>
      <div class="img-wrapper">
        <img src='../assets/im3.png' alt=''>
      </div>
    </div>
    <div class="item">
      <button>
        <a href="https://oknalider21.ru/" target="_blank" rel="noopener">
          <p>Окна Лидер</p>
        </a>
      </button>
      <div class="img-wrapper">
        <img src='../assets/im4.png' alt=''>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import gsap from 'gsap';

export default {
  name: 'About',
  
  setup() {
    onMounted(() => {

      const grid = document.querySelector('.grid')
      const items = document.querySelectorAll('.item')

      items.forEach((item) => {
        item.addEventListener('mouseenter', () => {
          gsap.to(grid, {
            '--track': '2fr',
            duration: 0.3,
          })
          gsap.to(item, {
            '--innerTrack': '1fr',
            duration: 0.3,
          })
        })

        item.addEventListener('mouseleave', () => {
          gsap.to(grid, {
            '--track': '1fr',
            duration: 0.3,
          })
          gsap.to(item, {
            '--innerTrack': '0fr',
            duration: 0.3,
          })
        })
      })
    })

    // onUnmounted(() => {
    //   grid.value
    // });
  }

}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
body {
  font-family: Merriweather, serif;
  margin: 0;
  padding: 1vw;
  display: grid;
  place-items: center;
  min-height: 100vh;
  background: #1b1f21;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  transition: transform 300ms;
  pointer-events: none;
}
.img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.grid {
  --track: 1;
  display: grid;
  max-width: 100vw;
  width: 100%;
  transition: grid-template 300ms;
}
.item {
  --i: 0.5vw;
  --c1: deeppink;
  --c2: orange;
  background: repeating-linear-gradient(var(--a, 45deg), var(--c1), var(--c1) var(--i), var(--c2) var(--i), var(--c2) calc(var(--i) * 2));
  display: grid;
  place-items: center;
  grid-template: 1fr / 1fr var(--innerTrack, 0);
  overflow: hidden;
  aspect-ratio: 8 / 3;
  transition: filter 200ms;
}
.item:hover {
  grid-template: 1fr / 1fr var(--innerTrack, 0);
}
.item:hover img {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.item:nth-child(2),
.item:nth-child(3) {
  --c2: darkorchid;
  --p: 50% 100%;
  background: repeating-radial-gradient(circle at var(--p), var(--c1), var(--c1) var(--i), var(--c2) var(--i), var(--c2) calc(var(--i) * 2));
}
.item:nth-child(3) {
  --p: 50% 0;
  --c1: cornflowerblue;
}
.item:nth-child(4) {
  --a: -45deg;
  --c1: cornflowerblue;
  --c2: turquoise;
}
.item p {
  background: #333333;
  color: white;
  padding: 0.5vw 0.75vw;
  font-size: clamp(1.1vw, 5vw, 2.8vw);
  transition: opacity 300ms;
}
.grid:has(.item:first-child:hover) .item:not(:first-child),
.grid:has(.item:nth-child(2):hover) .item:not(:nth-child(2)),
.grid:has(.item:nth-child(3):hover) .item:not(:nth-child(3)),
.grid:has(.item:nth-child(4):hover) .item:not(:nth-child(4)) {
  filter: grayscale(10%) brightness(250%) contrast(30%);
}
.grid:has(.item:first-child:hover) .item:not(:first-child) p,
.grid:has(.item:nth-child(2):hover) .item:not(:nth-child(2)) p,
.grid:has(.item:nth-child(3):hover) .item:not(:nth-child(3)) p,
.grid:has(.item:nth-child(4):hover) .item:not(:nth-child(4)) p {
  opacity: 0;
}
button {
  background: none;
  border: none;
}
a {
  text-decoration: none;
}

@media (min-width: 50em) {
    .grid {
        grid-template: 1fr 1fr / 1fr 1fr;
        aspect-ratio: 2 / 1;
    }
    .item {
        aspect-ratio: auto;
    }
    .grid:has(.item:first-child:hover) {
        grid-template: var(--track) 1fr / var(--track) 1fr;
    }
    .grid:has(.item:nth-child(2):hover) {
        grid-template: var(--track) 1fr / 1fr var(--track);
    }
    .grid:has(.item:nth-child(3):hover) {
        grid-template: 1fr var(--track) / var(--track) 1fr;
    }
    .grid:has(.item:nth-child(4):hover) {
        grid-template: 1fr var(--track) / 1fr var(--track);
    }
}

@media (max-width: 767px) {

  .item p {
    font-size: 25px;
  }

}
</style>