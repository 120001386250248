<template>
    <div class="ee">
        <div class="mr"></div>
        <h2>
            Хотелось бы Вам на своем предприятии:
        </h2>
        <ul>
            <li>
                увеличить прибыль,
            </li>
            <li>
                снизить затраты,
            </li>
            <li>
                повысить производительность,
            </li>
            <li>
                повысить качество товаров и услуг,
            </li>
            <li>
                повысить эффективность внутренних коммуникаций,
            </li>
            <li>
                получать актуальные и достоверные сведения от первоисточников?
            </li>
        </ul>
        <h2>
            Это просто!
        </h2>
        <h1>
            Онлайн-программа для управления предприятием с телефона
        </h1>
        <h3>
            Интерфейс руководителя предприятием:
        </h3>
        <div class="ie">
            <img class="imm" alt="Web-сервис для управления предприятием" src="../assets/int7.jpg">
            <img class="imm" alt="Web-сервис интерфейс менеджера по продажам" src="../assets/int8.jpg">
            <img class="imm" alt="Web-сервис" src="../assets/int9.jpg">
        </div>
        <h3>
            Интерфейс менеджера по продажам:
        </h3>
        <div class="ie">
            <img class="imm" alt="Web-сервис для управления предприятием" src="../assets/int1.jpg">
            <img class="imm" alt="Web-сервис интерфейс менеджера по продажам" src="../assets/int2.jpg">
            <img class="imm" alt="Web-сервис" src="../assets/int3.jpg">
        </div>
        <h3>
            Интерфейсы мастеров производственных участков:
        </h3>
        <div class="ie">
            <img class="imm" alt="Web-сервис для управления предприятием" src="../assets/int4.jpg">
            <img class="imm" alt="Web-сервис интерфейс менеджера по продажам" src="../assets/int5.jpg">
            <img class="imm" alt="Web-сервис" src="../assets/int6.jpg">
        </div>
        <div class="mr"></div>
        <h2>
            Отдельные интерфейсы для каждого сотрудника
        </h2>
        <h2>
            Это реально!
        </h2>
        <p>
            Данная программа: <br>
            - может быть масштабирована для любой отрасли; <br>
            - работает как сайт круглосуточно, потому что размещена на хостинге, подключена к домену; <br>
            - не требует специальной установки или скачивания, поэтому не занимает память устройств; <br>
            - работает на любых устройствах: персональные компьютеры, мобильные телефоны; <br>
            - данные защищены надежной авторизацией через логин и пароль; <br>
            - разделена по ролям: руководитель компании видит все данные, <br> а остальные сотрудники видят выборочные данные, достаточные для выполнения своих функций; <br>
            - работает в режиме реального времени, <br>
            - все данные своевременные и актуальные.
        </p>
        <h2>
            Стоимость
        </h2>
        <p class="txt">
          Разработка системы от 50.000 рублей без абонентской платы<br>
          4 интерфейса (рабочих места) включены в стоимость, <br>
          каждый дополнительный интерфейс стоит 10.000 рублей. <br>
          Домен от 150 рублей/год <br>
          Хостинг от 700 рублей/месяц <br>
          Срок разработки от 3 недель <br>
          Гарантия - 1 год              
        </p>
        <div class="mr"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.ee {
    background: white;
}
h2, h3, li, p {
    color: #2c3e50;
}
.mr {
    height: 10vh;
}
h1 {
    margin-top: -0.5vh;
    font-size: 2vw;
    color: #E36016;;
}
h2 {
    font-size: 1.7vw;
}
h3 {
    font-size: 1.5vw;
}
li, p {
    list-style-type: none; /* Убираем маркеры */
    line-height: 4.5vh;
    font-size: 1.3vw;
}
.ie {
    display: flex;
    justify-content: center;
}
.imm {
    width: 25vw;
}
.mr {
    height: 4vh;
}

@media (max-width: 767px) {

    .ie {
        display: block;
    }
    .imm {
        width: 100%;
    }
    h1 {
        font-size: 25px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 17px;
    }
    li, p {
        font-size: 16px;
        width: 80%;
        margin: auto;
    }

}
</style>