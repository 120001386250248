<template>
  <div class="mm">
    <router-link to="/">
      <img class="imm" alt="Web-студия Марио" src="../assets/mario.png">
    </router-link>
    <h1>
      Web-студия "Марио"
    </h1>
    <img class="imq" alt="Сайты, лендинги, web сервисы с личным кабинетом, CRM ERP системы" src="../assets/qu.png">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.mm {
  display: flex;
  justify-content: space-evenly;
  background: #555555;
}
.imm {
  width: 7.3vw;
  height: 10vw;
  margin-top: 2vw;
  margin-bottom: 10px;
}
h1 {
  color: #fff;
  /* text-shadow:
    0 0 2px #fff,
    0 0 2px #fff,
    0 0 2px #fff,
    0 0 42px #E36016,
    0 0 82px #E36016,
    0 0 92px #E36016,
    0 0 102px #E36016,
    0 0 151px #E36016; */
  border: 0.2rem solid #fff;
  border-radius: 2rem;
  padding: 0.4em;
  box-shadow: 0 0 .2rem #fff,
              0 0 .2rem #fff,
              0 0 2rem #E36016,
              0 0 0.8rem #E36016,
              0 0 2.8rem #E36016,
              inset 0 0 1.3rem #E36016;
  
  font-family: cursive;
  font-size: 4vw;
  animation: flicker 2.5s infinite alternate;     
}
/* Flickering animation */
@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
      text-shadow:
      0 0 2px #fff,
      0 0 2px #fff,
      0 0 2px #fff,
      0 0 40px #E36016,
      0 0 80px #E36016,
      0 0 90px #E36016,
      0 0 100px #E36016,
      0 0 150px #E36016;
  }
  20%, 24%, 55% {        
    text-shadow: none;
  }
}
.imq {
  width: 27vw;
}

@media (max-width: 767px) {

  .imm {
    width: 15vw;
    height: 20vw;
    margin-top: 2vw;
    margin-bottom: 2px;
  }
  h1 {
    font-size: 25px;
  }
  .imq {
    display: none;
  }

}

</style>