<template>
  <Mario />
  <div class="hh">
    <Head />
  </div>
  <div class="grid">
    <Afi />
    <Pres />
  </div>
  <Zag />
  <Info />
  <Zag2 />
  <Opis />
  <Foot />
</template>

<script>
import Mario from '@/components/Mario.vue'
import Head from '@/components/Head.vue'
import Afi from '@/components/Afi.vue'
import Pres from '@/components/Pres.vue'
import Zag from '@/components/Zag.vue'
import Info from '@/components/Info.vue'
import Zag2 from '@/components/Zag2.vue'
import Opis from '@/components/Opis.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'HomeView',
  components: {
    Mario,
    Head,
    Afi,
    Pres,
    Zag,
    Info,
    Zag2,
    Opis,
    Foot,
  }
}
</script>
<style scoped>
.hh {
  position: sticky;
  top: 0;
  z-index: 10;
}
.grid {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
}

@media (max-width: 767px) {

  .grid {
    display: block;
  }

}
</style>
