<template>
  <div>
    <div id="grid">
      <div class="left">
        <h1 class="zag">
          Сайт
        </h1>            
        <p class="txt">
          Разработка от 30.000 рублей <br>
          Домен от 150 рублей/год <br>
          Хостинг от 0 рублей/год <br>
          Срок разработки от 1 недели <br>
          Гарантия - 1 год
        </p>
      </div>
      <div class="center">
        <h1 class="zag">
          Онлайн-сервис с личным кабинетом
        </h1>
        <p class="txt">
          Разработка от 40.000 рублей <br>
          Домен от 150 рублей/год <br>
          Хостинг от 0 рублей/год <br>
          Срок разработки от 2 недель <br>
          Гарантия - 1 год
        </p>
      </div>
      <div class="right">
        <h1 class="zag">
          CRM, ERP системы
        </h1>
        <p class="txt">
          Разработка от 50.000 рублей <br>
          Домен от 150 рублей/год <br>
          Хостинг от 700 рублей/месяц <br>
          Срок разработки от 3 недель <br>
          Гарантия - 1 год              
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#grid {
  height: 100vh;
  transition: 500ms;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.3vw;
  background: gray;
}
:where(.left, .center, .right) {
  background: white;
  transition: 300ms;
}
:where(.left, .center, .right):hover {
  background: white;
}
#grid:has(.left:hover) {
  grid-template-columns: 2fr 0.5fr 0.5fr;
}
#grid:has(.center:hover) {
  grid-template-columns: 0.5fr 2fr 0.5fr;
}
#grid:has(.right:hover) {
  grid-template-columns: 0.5fr 0.5fr 2fr;
}
h1 {
  font-size: 2vw;
  margin-top: 20vh;
  color: #E36016;
}
p {
  font-size: 1.7vw;
  line-height: 5vh;
}
:where(.zag, .txt) {
  color: #2c3e50;
}
:where(.txt):hover {
  color: #2c3e50;
}

@media (max-width: 767px) {

  #grid {
    display: block;
    height: auto;
    background: white;
  }
  h1 {
    margin-top: 0;
    font-size: 20px;
  }
  p {
    font-size: 19px;
  }

}
</style>