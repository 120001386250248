<template>
    <div class="pres">
        <div class="mr"></div>
        <div>
            <h2 class="animate__animated animate__backInDown">
                Занимаемся разработкой:
            </h2>
            <h3 class="animate__animated animate__backInLeft">
                Сайтов
            </h3>
            <h3 class="animate__animated animate__backInUp">
                Онлайн-сервисов с ЛК
            </h3>
            <h3 class="animate__animated animate__zoomInDown">
                CRM, ERP систем
            </h3>
            <p class="pn">Отправить заявку</p>
            <div class="for">
                <form ref="form" @submit.prevent="sendEmail">
                <input 
                    class="un"
                    type="text" 
                    placeholder="Имя" 
                    v-model="user_name" 
                    name="user_name" 
                />
                <input
                    class="nm"
                    required
                    type="number"
                    placeholder="Телефон"
                    v-model="user_phone"
                    name="user_phone"
                />
                <textarea
                    type="text"
                    placeholder="Добавьте сообщение..."
                    v-model="message"
                    name="message"
                />  
                <div class="vis" 
                    v-show="visible"
                >
                    <p class="vp">
                    Ваше сообщение успешно отправлено!
                    </p> 
                </div>              
                <button class="bv" 
                    v-show="novisible"
                >
                    Отправить
                </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import 'animate.css';
import emailjs from "@emailjs/browser";

export default {
    name: 'Pres',
    data: () => ({
        user_name: '',
        user_phone: '',
        message: '', 
        visible: false, 
        novisible: true,  
    }),
    methods: {
        sendEmail: function(event) {
        emailjs.sendForm(
        "service_zpcd90y",
        "template_cnf2sgn",
        this.$refs.form,
        "3C04rr7UWG_7mE546"
        )
        .then(
        (result) => {
        console.log("SUCCESS!", result.text);
        },
        (error) => {
        console.log("FAILED...", error.text);
        }
        );
        this.user_name = '';
        this.user_email = '';
        this.user_phone = '';
        this.message = '';
        event.target.reset();
        this.visible = true;
        setTimeout(() => this.visible = false, 7000);
        this.novisible = false;
        setTimeout(() => this.novisible = true, 7000);
        },
    },
}
</script>

<style scoped>
.pres {
    width: 48.5vw;
}
.mr {
    height: 4vh;
}
h2, h3 {
    /* color: white; */
    color: #2c3e50;
    font-size: 2vw;
}
.animate__backInLeft {
    animation-fill-mode: both;
    animation-delay: 1s;
}
.animate__backInUp {
    animation-fill-mode: both;
    animation-delay: 2s;
}
.animate__zoomInDown {
    animation-fill-mode: both;
    animation-delay: 3s;
}
.pn {
    font-size: 2vw;
    color: #E36016;
}
.for {
    display: flex;
    justify-content: center;
}
form {
    display: flex;
    flex-direction: column;
    width: 20vw;
}
.un {
    background: rgb(218, 217, 217);
    border: gray;
    height: 0.2vh;
    padding: 3vh;
    font-size: 1vw;
}
.nm {
    margin-top: 2vh;
    background: rgb(218, 217, 217);
    border: grey;
    height: 0.2vh;
    padding: 3vh;
    font-size: 1vw;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance:textfield;
}
textarea {
    margin-top: 2vh;
    background: rgb(218, 217, 217);
    border: gray;
    height: 7vh;
    padding: 3vh;
    font-size: 1vw;
}
button {
    background-color: #E36016;
    border: #E36016;
    color: white;
    height: 7vh;
    font-size: 1.8vw;
    transition: all 0.4s ease;
    margin-top: 2vh;
    cursor: pointer;
}
button:hover {
    background-color: #ad4a10;
    border: #ad4a10;
    color: white;
    transition: all 0.4s ease;
}
.vp {
    font-size: 2vw;
    color: lawngreen;
}

@media (max-width: 767px) {

    .pres {
        width: 100vw;
    }
    h2, h3 {
        font-size: 25px;
    }
    .pn {
        font-size: 30px;
    }
    form {
        width: 90vw;
    }
    .un, .nm, textarea {
        font-size: 17px;
    }
    button, .vp {
        font-size: 25px;
    }

}
</style>