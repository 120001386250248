<template>
    <div class="ff">

    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.ff {
    height: 70vh;
    background-image: url(../assets/m2.png);
    background-size: cover;
}

@media (max-width: 767px) {

    .ff {
        height: 20vh;
    }

}
</style>