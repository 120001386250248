<template>
  <Mario />
  <div class="hh">
    <Head />
  </div>
  <Exemple />
  <Foot />
</template>

<script>
import Mario from '@/components/Mario.vue'
import Head from '@/components/Head.vue'
import Exemple from '@/components/Exemple.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'HomeView',
  components: {
    Mario,
    Head,
    Exemple,
    Foot,
  }
}
</script>

<style scoped>
.hh {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
